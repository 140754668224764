import { subjectAbility } from '@/constant'

/*
 * pageTitle : get from @/libs/i18n/locales/[id / en].json
 * in purpose to support multilanguage
 */

export default [
  // CH Generate
  {
    path: '/transactions/chbacc/ch-generate',
    name: 'apps-transactions-ch-generate-list',
    component: () => import('@/views/apps/transactions/ch-bacc/ch-generate/Index.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.ch.moduleName',
      action: 'read',
      resource: subjectAbility.TxChGenerate
    }
  },
  {
    path: '/transactions/chbacc/ch-generate/:id',
    name: 'apps-transactions-ch-generate-detail',
    component: () => import('@/views/apps/transactions/ch-bacc/ch-generate/Detail.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.ch.moduleName',
      action: 'read',
      resource: subjectAbility.TxChGenerate
    }
  },
  // CH
  {
    path: '/transactions/ch-bacc/ch',
    name: 'apps-transactions-ch-transaction-list',
    component: () => import('@/views/apps/transactions/ch-bacc/ch/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.ch.moduleName',
      action: 'read',
      resource: subjectAbility.TxCh
    }
  },
  {
    path: '/transactions/ch-bacc/ch/generate',
    name: 'apps-transactions-ch-transaction-generate',
    component: () => import('@/views/apps/transactions/ch-bacc/ch/Generate.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.ch.moduleName',
      action: 'read',
      resource: subjectAbility.TxCh
    }
  },
  {
    path: '/transactions/ch-bacc/ch/add',
    name: 'apps-transactions-ch-transaction-add',
    component: () => import('@/views/apps/transactions/ch-bacc/ch/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.ch.moduleName',
      action: 'read',
      resource: subjectAbility.TxCh
    }
  },
  {
    path: '/transactions/ch-bacc/ch/edit/:id',
    name: 'apps-transactions-ch-transaction-edit',
    component: () => import('@/views/apps/transactions/ch-bacc/ch/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.ch.moduleName',
      action: 'read',
      resource: subjectAbility.TxCh
    }
  },
  {
    path: '/transactions/ch-bacc/ch/detail/:id',
    name: 'apps-transactions-ch-transaction-detail',
    component: () => import('@/views/apps/transactions/ch-bacc/ch/Detail.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.ch.moduleName',
      action: 'read',
      resource: subjectAbility.TxCh
    }
  },

  // BACC
  {
    path: '/transactions/ch-bacc/bacc',
    name: 'apps-transactions-bacc-transaction-list',
    component: () => import('@/views/apps/transactions/ch-bacc/bacc/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.bacc.moduleName',
      action: 'read',
      resource: subjectAbility.TxBacc
    }
  },
  {
    path: '/transactions/ch-bacc/bacc/add',
    name: 'apps-transactions-bacc-transaction-add',
    component: () => import('@/views/apps/transactions/ch-bacc/bacc/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.bacc.moduleName',
      action: 'create',
      resource: subjectAbility.TxBacc
    }
  },
  {
    path: '/transactions/ch-bacc/bacc/edit/:id',
    name: 'apps-transactions-bacc-transaction-edit',
    component: () => import('@/views/apps/transactions/ch-bacc/bacc/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.bacc.moduleName',
      action: 'read',
      resource: subjectAbility.TxBacc
    }
  },
  {
    path: '/transactions/ch-bacc/purchase-invoice/prints/:id',
    name: 'apps-transactions-bacc-purchase-invoice-prints',
    component: () => import('@/views/apps/transactions/purchase/invoice/Print.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.invoice.moduleName',
      action: 'read',
      resource: subjectAbility.TxBacc
    }
  },
  {
    path: '/transactions/ch-bacc/sales-invoice/prints/:id',
    name: 'apps-transactions-bacc-sales-invoice-prints',
    component: () => import('@/views/apps/transactions/sales/invoice/Print.vue'),
    meta: {
      pageTitle: 'apps.transactions.sales.invoice.moduleName',
      action: 'read',
      resource: subjectAbility.TxBacc
    }
  },


  {
    path: '/transactions/ch-bacc/bacc-ar',
    name: 'apps-transactions-bacc-ar-list',
    component: () => import('@/views/apps/transactions/ch-bacc/bacc-ar/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.bacc.moduleName',
      action: 'read',
      resource: subjectAbility.TxBacc
    }
  },
  {
    path: '/transactions/ch-bacc/bacc-ar/add',
    name: 'apps-transactions-bacc-ar-add',
    component: () => import('@/views/apps/transactions/ch-bacc/bacc/FormCreate.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.bacc.moduleName',
      action: 'create',
      resource: subjectAbility.TxBacc
    }
  },
  {
    path: '/transactions/ch-bacc/bacc-ar/receive',
    name: 'apps-transactions-bacc-ap-add',
    component: () => import('@/views/apps/transactions/ch-bacc/bacc/FormReceive.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.bacc.actions.receive',
      action: 'create',
      resource: subjectAbility.TxBacc
    }
  },
  {
    path: '/transactions/ch-bacc/bacc-ar/edit/:id',
    name: 'apps-transactions-bacc-ar-edit',
    component: () => import('@/views/apps/transactions/ch-bacc/bacc-ar/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.bacc.moduleName',
      action: 'read',
      resource: subjectAbility.TxBacc
    }
  },
  // Receive Payment
  {
    path: '/transactions/ch-bacc/receive-payment/list',
    name: 'apps-transactions-chbacc-receive-payment',
    component: () => import('@/views/apps/transactions/ch-bacc/receive-payment/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.receivePayment.moduleName',
      action: 'read',
      resource: subjectAbility.TxChBaccReceivePayment
    }
  },
  {
    path: '/transactions/ch-bacc/receive-payment/invoice',
    name: 'apps-transactions-chbacc-receive-payment-add-per-invoice',
    component: () => import('@/views/apps/transactions/ch-bacc/receive-payment/FormPerInvoice.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.receivePayment.moduleName',
      action: 'create',
      resource: subjectAbility.TxChBaccReceivePayment
    },
    props: true
  },
  {
    path: '/transactions/ch-bacc/receive-payment/detail/:id',
    name: 'apps-transactions-chbacc-receive-payment-detail',
    component: () => import('@/views/apps/transactions/ch-bacc/receive-payment/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.receivePayment.moduleName',
      action: 'read',
      resource: subjectAbility.TxChBaccReceivePayment
    }
  },
  {
    path: '/transactions/ch-bacc/receive-payment/prints/:id',
    name: 'apps-transactions-chbacc-receive-payment-prints',
    component: () => import('@/views/apps/transactions/ch-bacc/receive-payment/Print.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.receivePayment.moduleName',
      action: 'read',
      resource: subjectAbility.TxChBaccReceivePayment
    }
  },
  // End Receive Payment
  // Payment BACC
  {
    path: '/transactions/ch-bacc/payment-bacc/list',
    name: 'apps-transactions-bacc-payment-list',
    component: () => import('@/views/apps/transactions/ch-bacc/payment-bacc/Index.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.paymentBacc.moduleName',
      action: 'read',
      resource: subjectAbility.TxBaccPaymnet
    }
  },
  {
    path: '/transactions/ch-bacc/payment-bacc/invoice',
    name: 'apps-transactions-bacc-payment-per-voucher-add',
    component: () => import('@/views/apps/transactions/ch-bacc/payment-bacc/FormPerVoucher.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.paymentBacc.moduleName',
      action: 'create',
      resource: subjectAbility.TxBaccPaymnet
    },
    props: true
  },
  {
    path: '/transactions/ch-bacc/payment-bacc/add',
    name: 'apps-transactions-bacc-payment-add',
    component: () => import('@/views/apps/transactions/ch-bacc/payment-bacc/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.paymentBacc.moduleName',
      action: 'read',
      resource: subjectAbility.TxBaccPaymnet
    }
  },
  //Reports  
  {
    path: '/report/ch-bacc-detail/',
    name: 'apps-report-ch-bacc-details',
    component: () => import('@/views/apps/report/ChBaccDetail.vue'),
    meta: {
      pageTitle: 'apps.reportChBacc.chBaccDetail.moduleName',
      action: 'read',
      resource: subjectAbility.ReportChDetail
    }
  }
]